export default {
  siteTitle: 'Get bonus',
  blogerName: 'ZEZI',
  socialsList: [
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@OZezii'
    },
    {
      name: 'instagram',
      url: 'https://www.instagram.com/o_zezinyt/'
    },
    {
      name: 'telegram',
      url: 'https://t.me/+fxOtF-AO6843ZDM5'
    }
  ],
  projects: [
    {
      name: '1go',
      url: 'https://1go-irit.com/c05db059b',
      gameTitle: 'Book of Kemet (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'monro',
      url: 'https://monrocasino.life/c9bfd60de',
      gameTitle: 'Burning Chilli X (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'drip',
      url: 'https://dripcasino.life/c87c7dbc1',
      gameTitle: 'Alien Fruits (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'starda',
      url: 'https://stardacasino.life/c4b3c8fa7',
      gameTitle: 'Starda Queen (BGaming)',
    },
    {
      name: 'legzo',
      url: 'https://legzocasino.life/cb78b2b91',
      gameTitle: 'Legzo Punk (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://freshcasino.life/c04f9210a',
      gameTitle: 'Doors Of Fresh (BGaming)',
    },
    {
      name: 'izzi',
      url: 'https://izzicasino.life/cc9d9ce0e',
      gameTitle: 'Izzi Art (BGaming)',
    },
    {
      name: 'sol',
      url: 'https://solcasino.life/c023a25cc',
      gameTitle: 'Doors Of Sol (BGaming)',
    },
    {
      name: 'jet',
      url: 'https://jetcasino.life/c463c407f',
      gameTitle: 'Jet Air (BGaming)',
    }
  ],
  headerText: 'Cadastre-se com um código promocional <strong>ZEZI</strong> e receba 50 rodadas grátis nos jogos abaixo',
  yourPromocode: 'Seu código promocional',
  gameBonus: 'Bónus no jogo',
  promocode: 'ZEZI',
  casinoBonus1: '<strong>100%</strong><div>No primeiro depósito</div>',
  casinoBonus2: '<strong>50FS</strong><div>Para cadastro com código promocional</div>',
  firstDeposit: 'DE PRIMEIRO DEPÓSITO',
  copyMessage: 'Código promocional copiado',
  theme: 'dark'
};
